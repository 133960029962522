import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/button';
import ThankYouImage from '../../../assets/images/thankyou-img.png';
import { RouteKeys } from '../../route-keys';
import { actions as registrationActions } from '../../../store/reducers/payment';
import { actions as dashboardActions } from '../../../store/reducers/dashboard';
import { actions as authUserActions, AuthState } from '../../../store/reducers/authUser';
import { State } from '../../../store/interfaces';

const PaymentDetailsPage: React.SFC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const authUser = useSelector<State, AuthState>((state: State) => state.authUser);

  const goBackHome = async () => {
    if(!authUser.token){
      history.replace(RouteKeys.Auth)
    }else{
      dispatch(authUserActions.fetchProfile());
      dispatch(registrationActions.getCourse(false, false));
      dispatch(dashboardActions.fetchPaymentHistory());
      history.replace(RouteKeys.Payment);
    }  
  };

  // eslint-disable-next-line class-methods-use-this
  const renderImage = () => {
    return (
      <div className="Thankyou-img">
        <img src={ThankYouImage} className="img-fluid" alt="thankyou" />
      </div>
    );
  };

  // eslint-disable-next-line class-methods-use-this
  const renderHeader = () => {
    return (
      <h3 className="font-weight-bold text-uppercase text-primary pt-5 pb-2">
        Thank you
      </h3>
    );
  };

  // eslint-disable-next-line class-methods-use-this
  const renderDescription = () => {
    return (
      <p className="font-lg text-muted mb-5">
        <span className="mr-1 mb-1 d-sm-block">
          For doing payment with excellence driving.
        </span>
      </p>
    );
  };

  const renderBackButton = () => {
    return (
      <Button type="button" className="Button Button--md" onClick={goBackHome}>
        {authUser?.token ? "Back to Payment" : "Back to Login" }
      </Button>
    );
  };

  const renderBody = () => {
    return (
      <div className="Thankyou text-center py-5 my-4">
        <div className="container">
          {renderImage()}
          {renderHeader()}
          {renderDescription()}
          {renderBackButton()}
        </div>
      </div>
    );
  };

  return <>{renderBody()}</>;
};

export default PaymentDetailsPage;
